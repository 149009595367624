import React from "react";
import {Redirect} from "react-router-dom";

import { useLocation } from 'react-router-dom'

function RedirectAnonymous() {    
    const [redirect, setRedirect] = React.useState(false);
    const location = useLocation();

    React.useEffect(()=>{
        const loggedIn = window.localStorage.getItem('taitest.loggedin');
        if(loggedIn !== 'true'){
            setRedirect(true);
        }
    })

    return (
        <React.Fragment>
            {redirect && (<Redirect to={{pathname: '/login', state: location}} />)}
        </React.Fragment>
    ) 
}

export default RedirectAnonymous;