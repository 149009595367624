
import React from "react";
import {Link, useLocation} from "react-router-dom";  
import {COCKPIT_HOST, COCKPIT_UPLOADS_DIR, COCKPIT_API_IMAGE, COCKPIT_GET_TOKEN} from "../config";

import useRemoteServer from '../hooks/useRemoteServer';
import RedirectAnonymous from './Authentication/RedirectAnonimus';

function AboutUsers({coverImage, pageTitle}) {
    const module = "about";

    const location = useLocation()
    const pathArr = location.pathname.split("/")
    const alias = pathArr[pathArr.length-1]

    const remoteServerInterface = useRemoteServer();
    const [aboutUsersData, setAboutUsersData] = React.useState({Content_section:[], Image_news:{}});
    const [blogData, setBlogData] = React.useState({entries:[]});
    const loggedIn = localStorage.getItem('taitest.loggedin');


    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);
        setAboutUsersData(list)
    },[])
    React.useEffect(async () =>{
        const blogList = await remoteServerInterface.getBlogDataHome();
        setBlogData(blogList);
    },[])

    const themeList = aboutUsersData.Content_section.map(function(val, ind) {

        if (alias === "about-patients" && val.value.audience.includes("patient")) {
            return (
                <Link to={val.value.link} key={ind} className="btn-svg-box-wrapper py-3 px-3 d-flex align-items-center text-decoration-none">
                    <div className="btn-svg-box position-relative h-100 d-flex flex-column align-items-center justify-content-center w-100 pb-4 pt-5">
                        <div className="text-center h-50">
                            <h1 className="fw-light">{val.value.title}</h1>
                        </div>
                        <img className="mt-3 mb-5" src={COCKPIT_HOST+COCKPIT_UPLOADS_DIR+val.value.image.path} />
                        <div className="mb-2 action-text-wrapper w-100">
                            <h4 className="action-text fw-normal">{val.value.action_title}</h4>
                        </div>
                    </div>
                </Link>
            )
        } else if (loggedIn && alias === "about-medics" && val.value.audience.includes("medic")){
            return (
                <Link to={val.value.link} key={ind} className="btn-svg-box-wrapper py-3 px-3 d-flex align-items-center text-decoration-none">
                    <div className="btn-svg-box position-relative h-100 d-flex flex-column align-items-center justify-content-center w-100 pb-4 pt-5">
                        <div className="text-center h-50">
                            <h1 className="fw-light">{val.value.title}</h1>
                        </div>
                        <img className="mt-3 mb-5" src={COCKPIT_HOST+COCKPIT_UPLOADS_DIR+val.value.image.path} />
                        <div className="mb-2 action-text-wrapper w-100">
                            <h4 className="action-text fw-normal">{val.value.action_title}</h4>
                        </div>
                    </div>
                </Link>
            )
        }
    })

    const blogList = blogData.entries.map (function(val, ind) {
        return (
            <Link to={val.Theme.display+"/"+val.Alias_slug} key={ind} className="col-lg-4 col-md-4 col-sm-6 pb-5 px-3 d-flex align-items-center text-decoration-none">
                <div className="text-secondary blog-article">
                <div className="solid-circle position-relative mb-4">
                    <img className="blog-img w-100" src={COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+val.Image.path+"&w=461&h=295&o=1&m=thumbnail&q=80"} />
                    <span className="">
                        <img className="blog-hover-img position-absolute" src={COCKPIT_HOST+"storage/SVG/article_hover_icon.svg"} />
                    </span>
                    <div className="thin-red-line w-100"></div>
                </div>
                    <h4 className="fst-italic">{val.Title}</h4>
                    <p className="fs-5 fw-light text-primary">{val.Description_preview}</p>
                    <h5 className="fst-italic fw-bolder text-uppercase">Виж повече</h5>
                </div>
            </Link>
        )
    })

    return (
        <React.Fragment>
            {(alias === "about-medics")&&<RedirectAnonymous/>}
            <div className="w-100">
                <article className="pb-5">
                    <img className="blog-img w-100" src={coverImage ? COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+coverImage.path+"&w=1920&h=445&o=1&m=thumbnail&q=80" : ""} />
                    <div className="position-relative container">
                        <div className="w-100 cover-page-text position-absolute">
                            <div className="offset-8 col-4 background-light">
                                <span className="pe-4 d-flex justify-content-end">
                                    <h1 className="px-5 header text-uppercase fw-bold text-gray px-4 pt-4 pb-3">{pageTitle ? pageTitle : ""}</h1>
                                </span>
                            </div>
                        </div>
                    </div>   
                </article>
            </div>
            <div className="container pb-5">
                <article className="pb-5">   
                    <h1 className="pt-5 pb-1 mb-4 ps-0 fw-bolder display-1 text-light border-2 border-secondary">{aboutUsersData.Heading_content ? aboutUsersData.Heading_content : null}</h1>
                    <div className="row">
                        {themeList}
                    </div>
                </article>
                <article className="pb-5">   
                    <h1 className="pt-5 pb-1 mb-4 ps-0 fw-bolder display-1 text-light border-2 border-secondary">{aboutUsersData.Heading_news ? aboutUsersData.Heading_news : null}</h1>
                    <div className="row">
                        {blogList}
                    </div>
                </article>
            </div>
        </React.Fragment>
    );
}

export default AboutUsers;
