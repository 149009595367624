import {Link} from "react-router-dom";  

import {
    Container,
    List,
    ListItem,
    ListItemText,
    makeStyles
  } from "@material-ui/core";

  const useStyles = makeStyles({
    navItemFooter: {
        '& span' : {
            fontSize: `13px`
        }
    }
  })


function Footer({ menu, footerData }) {
    const classes = useStyles();

    let footerLine = footerData ? footerData.Footer_text : "";


    const listNav = menu.map (function(item) {
        const link = item.Alias_slug === 'home' ? '/' : item.Alias_slug;
        return (
            <Link to={`/${link}`} key={item.Title} className="d-flex align-items-center text-uppercase text-light text-decoration-none">
                <ListItem button>
                    <ListItemText  className={classes.navItemFooter} primary={item.Title} />
                </ListItem>
            </Link>
        )
    })

    return (
        <footer className="bg-secondary text-center pb-1 do-not-print">
            <Container maxWidth="md" className="d-flex justify-content-center flex-wrap">
                <List component="nav" aria-labelledby="footer navigation" className="d-flex justify-content-between">
                    {listNav}
                </List>
                <p className="small text-light">{footerLine}</p>
            </Container>
        </footer>
    );
}

export default Footer;

// return (
//     <footer className="bg-secondary text-center py-5 do-not-print">
//         <div className="d-flex justify-content-center">
//             <ul className="nav d-flex justify-content-between">
//                 {listNav}
//             </ul>
//         </div>
//     </footer>
// );
