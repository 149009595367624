import React from "react";

function PageContainer({children}){
    
    return (
        <div className="container py-5">{children}</div>
    )
}

export default PageContainer
