import React from "react";

import PageContainer from './../PageContainer';
import useRemoteServer from '../../hooks/useRemoteServer';

function Privacy() {
    const module = "privacy";

    const remoteServerInterface = useRemoteServer();
    const [privacy, setPrivacy] = React.useState({});

    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);

        setPrivacy(list)
    },[])

    return (
        <PageContainer>
            <h3 className="ps-2 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{privacy.Title ? privacy.Title : null}</h3>
            <article className="row pb-0 pt-1 pb-xxl-5" dangerouslySetInnerHTML={{__html: privacy.Description}}></article>
        </PageContainer>
    );
}

export default Privacy;