import React from "react";
import { Link} from "react-router-dom";  
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

function BlogBreadcrumbs({navData, blogData, alias, article}){
    
    const themeAlias = Object.assign({Title: ''}, navData.entries.find(item => item.Alias_slug === alias ));

    return(
        <React.Fragment>
            <Breadcrumbs separator="›" aria-label="breadcrumb" className="py-1 fw-normal font-family-base font-size-base">
                <Link to="/" className="text-decoration-none text-secondary">Началнa страница</Link>
                <Typography className="text-secondary fw-normal font-family-base font-size-base">Tеми</Typography>
                {article ? <Link to={`/blog/${alias}`} className="text-decoration-none text-secondary">{themeAlias.Title}</Link> : <Typography className="text-secondary fw-normal font-family-base font-size-base">{themeAlias.Title}</Typography>}
                {article&&<Typography className="text-secondary fw-normal font-family-base font-size-base">{blogData.entries[0] ? blogData.entries[0].Title : ""}</Typography>}
            </Breadcrumbs>
        </React.Fragment>
    )
}

export default BlogBreadcrumbs;