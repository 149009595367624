export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const COCKPIT_HOST = process.env.REACT_APP_COCKPIT_HOST;
export const COCKPIT_API = process.env.REACT_APP_COCKPIT_API;
export const COCKPIT_API_IMAGE = process.env.REACT_APP_COCKPIT_API_IMAGE;
export const COCKPIT_STORAGE_DIR = process.env.REACT_APP_COCKPIT_STORAGE_DIR;
export const COCKPIT_UPLOADS_DIR = process.env.REACT_APP_COCKPIT_UPLOADS_DIR;
export const COCKPIT_GET_TOKEN = process.env.REACT_APP_COCKPIT_GET_TOKEN;
export const COCKPIT_SAVE_TOKEN = process.env.REACT_APP_COCKPIT_SAVE_TOKEN;
export const COCKPIT_USER_TOKEN = process.env.REACT_APP_COCKPIT_USER_TOKEN;
// export const WEBSITE_BASE_PATH = process.env.REACT_APP_WEBSITE_BASE_PATH;
// export const WEBSITE_SITE_ROOT = process.env.REACT_APP_WEBSITE_SITE_ROOT;