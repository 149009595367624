import React from 'react';

const Question = ({text, number, testType, onSelected, answer}) => {
    const inputRadioName = `radio-choice-v-6-${testType}-${number}`;
    const inputScoreId = `i_score_${testType}_${number}`;
    const [score, setScore] = React.useState(answer ? answer : '');
    const handleChange = ev => {
        onSelected(number, ev.target.value);
        setScore(ev.target.value);
    }

    React.useEffect(()=>{
        setScore(answer ? answer : '');
    }, [answer])

    //Adding answers on each question
    const listAnswers = text.Answers.map((item, index) => {
        const answerNumber = index + 1 + '';
        return <div key={index} className="col-20-percent pe-0 d-flex align-items-center print-answer-style">
                    <input type="radio" name={inputRadioName} id={"radio-choice-"+answerNumber+"-question-"+number} value={answerNumber} className="" onChange={handleChange} checked={answerNumber === score}/>
                    <label htmlFor={"radio-choice-"+answerNumber+"-question-"+number} className="ps-2 small fw-normal">{answerNumber+". "+item.value}</label>
                </div>
        });

    return (
        <li className="row pb-2 print-questions-box">
            <div className="col-sm-11 col-12 col-print-11 px-0 pb-sm-0 pb-2">
                <div className={"px-3 py-md-3 py-2 h-100 me-sm-1 me-md-2 me-0 print-question-style question-"+number}>
                    <h6 className="fw-normal pb-md-2 pb-0"><span className="">{number}</span>. {text.Question}</h6> 		  
          												
                    <fieldset className="col-12 d-flex justify-content-between" data-role="controlgroup" data-mini="true">
                        <div className="row w-100">
                            {listAnswers}
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className="col-sm-1 col-12 col-print-1 score-box">
                <h3 className="h-100">
                    <input className="text-center fw-normal h-100 w-100 input-outline-none border-0 bg-transparent" id={inputScoreId} type="text" size="1" value={score} readOnly={true} />
                </h3>
                <div className="box"></div>
            </div>
        </li>
    )
}

export default Question;
