import React from 'react';
import TextField from '@material-ui/core/TextField';
import 'date-fns';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
}  from '@material-ui/pickers';

import Question from './Question';

function Tai10Questions({questions, answers, onTestReady, onQuestionSelected}) {
  
    const list = questions.map((item, index) => {
        const questionNumber = index + 1;
        return <Question key={index} text={item} number={questionNumber} testType='10' onSelected={onQuestionSelected} answer={answers.get(questionNumber)}/>
    });

    React.useEffect(()=>{
        if(answers.size === 10){
        const sporadic = [1, 2, 3, 4, 5].reduce((total, item) => { return total + parseInt(answers.get(item))}, 0);
        const deliberate = [6, 7, 8, 9, 10].reduce((total, item) => { return total + parseInt(answers.get(item))}, 0);;
        onTestReady({sporadic, deliberate}, answers);
        }
    }, [answers]);

    const [selectedDate, setSelectedDate] = React.useState(
        new Date()
    );

    const handleDateChange = React.useCallback((date) => {
        setSelectedDate(date);
    },[setSelectedDate]);

    return (
        <div>
            <div className="do-not-print">
                <h3 className="fw-bold fst-italic text-secondary pb-3">Тест за придържане към инхалатори (10 въпроса) TAI 10.</h3>
                <p className="text-dark lh-lg">
                    За да попълните въпросника, отбележете отговора, който най-добре описва настоящото Ви състояние. Отговорете на всички въпроси възможно най-честно. Точките съответстващи на всеки отговор, ще се появят автоматично в края на реда. След попълване на всички отговори, резултатът ще се появи в края на теста.
                    Попълването на дата и друга информация не е задължително.
                    След попълването на теста, можете да го принтирате или запазите в PDF формат, чрез бутона „Print/Save to PDF”.
                </p>
                <p className="pb-4">
                    Благодарим ви за сътрудничеството.
                </p>
            </div>
            <div className="row pt-3 px-lg-5 px-0 mx-lg-5 mx-0">
                <div className="">
                    <h4 className="ps-2 pb-2 fw-normal fst-italic text-secondary border-bottom border-2 border-gray print-border"><span className="fw-bold text-secondary">TAI 10.</span> Определяне на нивото на придържане</h4>
                    <div className="d-flex flex-flow-wrap justify-content-evenly py-3 print-test-info-style flex-sm-row flex-column text-center">
                        <div className="d-grid print-input-container">
                            <TextField id="standard-basic" className="mt-3" label="Име на пациент: " />
                        </div>
                        <div className="d-grid print-input-container">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Дата: "
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                    "aria-label": "change date"
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div>
                <ul className="pt-3 list-unstyled print-padding-none">
                    {list}
                </ul>
            </div>
        </div>
    );
}

export default Tai10Questions;