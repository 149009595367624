import React from "react";
import TextField from '@material-ui/core/TextField';

export default function InputField({label, name, value, onChange, validations, errors, setErrors, serverError, numRows, background}) {

    // const[focused, setFocused] = React.useState(false)
    // const ref = React.useRef(null);

    function validate(validations) {
        setErrors( (prev) => ({
            ...prev,
            [name]: validations
                .map(errorsFor => errorsFor(value))
                .filter((errorMsg) => errorMsg.length > 0) 
        }));
    }

    
    return (
        <TextField
            multiline
            rows={numRows}

            name= {name}
            id={name}
            variant={background}
            fullWidth
            value={value}
            label={label}
            onChange={(e)=>onChange(e.target.value)}
            // onFocus={()=>setFocused(true)}
            onBlur={()=>{
                // setFocused(false);
                validate(validations)
                setErrors = ''
            }}
            // onInput ={()=>{
            //     validate(validations)
            // }}
            helperText = {errors.length > 0 ? errors.join(', ') : serverError}   
            error = {errors.length > 0 || serverError}
        />    

        // <div className={`form-field ${focused ? 'is-focused' : ''} ${value.length > 0 ? 'has-value' : '' }`}>
        //     <div className='control'>
        //         <label onClick={() => ref.current.focus()} >{label}</label>
        //         <input
        //             ref={ref}
        //             type='text'
        //             value={value}
        //             onChange={(e)=>onChange(e.target.value)}
        //             onFocus={()=>setFocused(true)}
        //             onBlur={()=>{
        //                 setFocused(false);
        //                 validate(validations)
        //             }}
        //         />
        //     </div>
        //     { errors.length > 0 ? (
        //     <div className='has-error'>{errors.join(", ")}</div>
        //     ) : null}
        // </div>
    )
}
