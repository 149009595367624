
import React from "react";
import {Link } from "react-router-dom";  
import {COCKPIT_HOST, COCKPIT_API_IMAGE, COCKPIT_GET_TOKEN} from "../../config";

function BlogListing({blogListData, button, onLoadMore}) {

    const loggedIn = localStorage.getItem('taitest.loggedin');

    const blogList = blogListData.entries.map(function(val, ind) {
        return (
            <Link to={`/blog/${val.Theme.display+"/"+val.Alias_slug}`} key={ind} className="col-lg-6 col-md-6 col-sm-6 pb-5 px-3 d-flex align-items-center text-decoration-none">
                <div className="text-secondary blog-article">
                <div className="solid-circle position-relative mb-4">
                    <img className="blog-img w-100" src={COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+val.Image.path+"&w=461&h=295&o=1&m=thumbnail&q=80"} />
                    <span className="">
                        <img className="blog-hover-img position-absolute" src={COCKPIT_HOST+"storage/SVG/article_hover_icon.svg"} />
                    </span>
                    <div className="thin-red-line w-100"></div>
                </div>
                    <h4 className="fst-italic">{val.Title}</h4>
                    <p className="fs-5 fw-light text-primary">{val.Description_preview}</p>
                    <h5 className="fst-italic fw-bolder text-uppercase">Виж повече</h5>
                </div>
            </Link>
        )
    })

    return (
        <React.Fragment>
            <div className="row pt-4">
                {blogList}
            </div>  
            {button && (<div className="d-flex justify-content-center">
                <div onClick={onLoadMore} className="mt-5 load-more-btn btn btn-secondary text-uppercase ">Зареди още</div>
            </div>)}
        </React.Fragment>
    );
}

export default BlogListing;
