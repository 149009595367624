import React from "react";
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect} from "react-router-dom";
import {PUBLIC_URL} from "./config";

import useRemoteServer from './hooks/useRemoteServer';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import Home from './components/Home/Home';
import AboutUsers from './components/AboutUsers';
import Blog from './components/Blog/Blog';
import Contacts from './components/Contacts';
import AboutTAI from './components/AboutTaiTest';
import Tai10 from './components/Tai10';
import Tai12 from './components/Tai12';
import Login from './components/Authentication/Login';
import Register from './components/Authentication/Register';
import UsefulInfo from './components/UsefulInfo'
import HToUse from './components/How2Use';
import CoockieBar from './components/CoockieBar';
import Legal from './components/Legal/LegalNotacy';
import Cookies from './components/Legal/CookiePolicy';
import Privacy from './components/Legal/PrivacyPolicy';
import CPanel from './components/CPanel.js';
import ScrollToTop from "./components/Navigate/ScrollToTop";
import NotFound from './components/404.js';
import Metatags from "./components/MetaData";
// import { createMuiTheme, ThemeProvider } from "@material-ui/core";

// const theme = createMuiTheme({
//     typography: {
//         fontFamily: 'Montserrat',
//         fontWeightLight: 300,
//         fontWeightRegular: 400,
//         fontWeightMedium: 500,
//         fontWeightBold: 700,
//         fontSize: 14,
//         h1: {
//             fontSize: "2.1875rem"
//         },
//         h2: {
//             fontSize: "1.75rem"
//         },
//         h3: {
//             fontSize: "1.5rem"
//         },
//         h4: {
//             fontSize: "1.3125rem"
//         },
//         h5: {
//             fontSize: "1rem"
//         },
//         h6: {
//             fontSize: "0.875rem"
//         }
//     }
// }) 

const routesFactory = (componentName, properties) => {
    switch(componentName){
        case 'home':
            return (
                <Route key={componentName} exact path="/">
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <AboutTAI />
                </Route>
        )
        case 'about-patients':
            return (
                <Route key={componentName} exact path={"/" + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <AboutUsers coverImage={properties.Page_cover} pageTitle={properties.Title}/>
                </Route>
            )
        case 'about-medics':
            return (
                <Route key={componentName} exact path={"/" + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <AboutUsers coverImage={properties.Page_cover} pageTitle={properties.Title}/>
                </Route>
            )
        case 'copd':
        case 'asthma':
        case 'therapy-adherence':
        case 'devices':
            return (
                <Route key={componentName} path={'/blog/' + componentName + '/:article?'}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <Blog coverImage={properties.Page_cover} pageTitle={properties.Title} alias={componentName}/>
                </Route>
            )    
        case 'contacts':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <Contacts />
                </Route>
            )
        case 'about-tai-test':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <AboutTAI />
                </Route>
            )
        case 'tai10':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <Tai10 />
                </Route>
            )
        case 'tai12':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <Tai12 />
                </Route>
            )
        case 'useful-info':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <UsefulInfo />
                </Route>
            )
        case 'request-for-use':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <HToUse />
                </Route>
            )
        case 'legal-notice':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <Legal />
                </Route>
            )
        case 'cookies-policy':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <Cookies />
                </Route>
            )
        case 'privacy-policy':
            return (
                <Route key={componentName} path={'/' + componentName}>
                    <Metatags title={properties.Meta_title} description={properties.Meta_description}/>
                    <Privacy />
                </Route>
            )
    }
}

function Main() {
    const module = "main";

    const remoteServerInterface = useRemoteServer();
    const [routers, setRouters] = React.useState([]);
    const [navData, setNavData] = React.useState([]);
    const [loggedIn, setLoggedIn] = React.useState(window.localStorage.getItem('taitest.loggedin'));

    React.useEffect(async ()=>{
        const navigation = await remoteServerInterface.getNavigation();
        const routersList = navigation.entries.map((item, index) => {
            return routesFactory(item.Alias_slug, item);
        });
        const mainList = await remoteServerInterface.getSingleton(module);

        routersList.push((<Route key="error"><NotFound /></Route>));
        setRouters(routersList);
        setNavData(navigation.entries.filter(item => item.Publish));
        setlogoPath(mainList.Logo.path);
        setMainInfo(mainList);
    },[])

 //Main info fetch   
    const [logoPath, setlogoPath] = React.useState("");
    const [mainInfo, setMainInfo] = React.useState([]);

    React.useEffect(async ()=>{
        const list = await remoteServerInterface.getSingleton(module);

        setlogoPath(list.Logo.path);
        setMainInfo(list);
    },[])

    const handleLogIn = () => {
        setLoggedIn(true);
    };

    // Re-write navigation array to parent-child structure
    const allParents = navData.filter(item => item._pid === null);
    const newMenu = allParents.map(parent => {
        const childrenList = navData.filter(item => (item._pid === parent._id)&&(item.Menu.indexOf('main') > -1));
        return Object.assign({}, parent, { children: childrenList });
    })


    return (
            <div id="AppWrapper">
                <Router basename={PUBLIC_URL} match={null}>
                    <ScrollToTop>
                        <Header className="content-header py-5 do-not-print" logo={logoPath} menu={newMenu.filter(item => item.Menu.indexOf('main') > -1)} loggedIn={loggedIn}/>
                        {/*<section className="content-wrapper d-flex justify-content-center print-container-style">*/}
                        <section className="content-wrapper print-container-style">
                            <Switch>
                                <Route key='registration' path={'/registration'}>
                                    <Register />
                                </Route>
                                <Route key='login' path={'/login'}>
                                    <Login onLogIn={handleLogIn}/>
                                </Route>
                                <Route key='panel' path={'/export'}>
                                    <CPanel />
                                </Route>
                                {routers}
                            </Switch>
                        </section>
                        <Footer className="content-footer" footerData={mainInfo} menu={navData.filter(item => item.Menu.indexOf('footer') > -1)}/>
                        <CoockieBar className="do-not-print" coockieData={mainInfo}/>
                    </ScrollToTop>
                </Router>
            </div>
    );
}

export default Main;
