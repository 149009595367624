import React from 'react';
import {BrowserRouter as Router, Link, NavLink, useLocation} from "react-router-dom"; 
import { COCKPIT_HOST, COCKPIT_API_IMAGE, COCKPIT_GET_TOKEN } from "../../config"; 
import {ListItem, ListItemText} from "@material-ui/core";

import useRemoteServer from '../../hooks/useRemoteServer';

function SidebarMenu({menuList}){

    const location = useLocation();

    const module = "navSidebar";
    const remoteServerInterface = useRemoteServer();
    const [sidebarData, setSidebarData] = React.useState({entries:[]});

    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);
        setSidebarData(list)
    },[])

    const imgHight = 50; 
    const imgWidth = ''; 
    const imgTaiTest = sidebarData.Tai_img ? COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+sidebarData.Tai_img.path+"&w="+imgWidth+"&h="+imgHight+"&o=1&m=thumbnail&q=80" : "";


    const LinkItem = menuList.entries.map((item)=>{
        return  <NavLink activeClassName="active" to={`/blog/${item.Alias_slug}`} key={item.Title} className="px-3 d-flex align-items-center text-secondary text-decoration-none">
                    <ListItem>
                        <ListItemText className="" primary={item.Title} />
                    </ListItem>
                </NavLink>
    })


    return(
        <React.Fragment>
            <aside className="col-4">
                <hr className="pt-1 mb-2 bg-secondary border-secondary border-top border-bottom border-1"/>
                <div className="sidebar-links-wrapper bg-light border-secondary border-top border-2 py-4 mb-3">
                    <h4 className="px-3 text-secondary">{sidebarData.Theme_title ? sidebarData.Theme_title : ""}</h4>
                        {LinkItem}
                </div>
                <NavLink className="sidebar-taitest-wrapper text-decoration-none" to={`/taitest/${sidebarData.Tai_link ? sidebarData.Tai_link.display : ""}`}>
                    <div className="d-flex justify-content-between align-items-center bg-light border-secondary border-top border-bottom border-2 px-3 py-4">
                        <h4 className="text-secondary mb-0">{sidebarData.Tai_title ? sidebarData.Tai_title : ""}</h4>
                        <div className="pe-4">
                            <img src={imgTaiTest} alt={sidebarData.Tai_title ? sidebarData.Tai_title : ""}/>
                        </div>
                    </div>
                </NavLink>
            </aside>
        </React.Fragment>
    )
}

export default SidebarMenu;