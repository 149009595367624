import React from "react";


function Contacts() {
    
    return (
        <React.Fragment>
            <div>   
                CONTACTS PAGE
            </div>
        </React.Fragment>
    );
}

export default Contacts;