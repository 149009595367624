import React from 'react';

import Question from './Question';


function Tai12Questions({questions, answers, onTestReady, onQuestionSelected}) {

    const list10 = questions.map((item, index) => {
        const questionNumber10 = index + 1;
        if ((index >= 0)&&(index < 10)){
            return <Question key={index} text={item} number={questionNumber10} testType='10' onSelected={onQuestionSelected} answer={answers.get(questionNumber10)}/>
        }
    });
    const list12 = questions.map((item, index) => {
        const questionNumber12 = index + 1;
        if (index > 9){
            return <Question key={index} text={item} number={questionNumber12} testType='12' onSelected={onQuestionSelected} answer={answers.get(questionNumber12)}/>
        }
    });


//   const sliceIndex = questions.length - 2;
//   const list10 = questions.slice(0, sliceIndex).map((item, index) => {
//     return <Question key={index} text={item} number={index + 1} testType='12' onSelected={handleQuestionSelected}/>
//   });
//   const list12 = questions.slice(sliceIndex).map((item, index) => {
//     return <Question key={index} text={item} number={index + 1} testType='12' onSelected={handleQuestionSelected}/>
//   });

    React.useEffect(()=>{
        if(answers.size === 12){
            const sporadic = [1, 2, 3, 4, 5].reduce((total, item) => { return total + parseInt(answers.get(item))}, 0);
            const deliberate = [6, 7, 8, 9, 10].reduce((total, item) => { return total + parseInt(answers.get(item))}, 0);
            const unconscious = [11, 12].reduce((total, item) => { return total + parseInt(answers.get(item))}, 0);
            onTestReady({sporadic, deliberate, unconscious}, answers);
        }
    }, [answers]);



    return (
        <div>
            <div className="row pt-3 px-lg-5 px-0 mx-lg-5 mx-0">
                <ul className="pt-3 list-unstyled print-padding-none">
                    {list10}
                </ul>
                <h4 className="ps-2 pb-2 mb-3 fw-normal fst-italic text-secondary border-bottom border-2 border-gray do-not-print"><span className="fw-bold text-secondary">TAI 12.</span></h4>
                <p className="print-test-descr">Отговарящият за пациента здравен специалист трябва да попълни следващите два въпроса според данните в медицинския картон на 
                пациента (въпрос 11) и след потвърждаване на техниките му на инхалиране (въпрос 12)</p>
                <ul className="pt-3 list-unstyled print-padding-none">
                    {list12}
                </ul>
            </div>  
        </div>
    );
}

export default Tai12Questions;