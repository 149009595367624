// import React from "react";
import CookieConsent from "react-cookie-consent";

function CoockieBar({coockieData}) {

    return (
        <CookieConsent
            debug={false}
            expires={90}
            disableStyles={true}
            location="bottom"
            buttonClasses="btn btn-secondary"
            containerClasses="d-flex bg-light px-3 py-4 col-lg-12 mb-0 position-fixed justify-content-between align-items-center do-not-print"
            contentClasses="fst-italic"
            buttonText={coockieData.Cookie_consent_button}
            >
            <p className="small mb-0">{coockieData.Cookie_consent_text+"  "}<a className="text-secondary" href={coockieData.Cookie_consent_link}>{coockieData.Cookie_consent_link_text}</a></p>
        </CookieConsent>
    )
}  

export default CoockieBar;          
