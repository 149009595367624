import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {COCKPIT_GET_TOKEN, COCKPIT_API_IMAGE, COCKPIT_HOST, COCKPIT_UPLOADS_DIR} from "../../config";

export default function TransitionsModal({videoData}) {
  
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button onClick={handleOpen} className="w-100 d-flex align-items-center border-0 bg-transparent p-0 position-relative">
        <img src={COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+videoData.Video_image.path+"&w=&h=500&o=1&m=thumbnail&q=80"} className="w-100"/>
        <div className="position-absolute blog-article-video">
            <img src={COCKPIT_HOST+"storage/SVG/circle_fill_play_icon.svg"} className=""/>
        </div>
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="d-flex justify-content-center align-items-center"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div>
            <video width="100%" controls autoPlay muted>
                <source src={COCKPIT_HOST+COCKPIT_UPLOADS_DIR+videoData.Video.path} type="video/mp4"></source>
                Your browser does not support HTML video.
            </video>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

// COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+videoData.Video_image.path+"&w=&h=295&o=1&m=thumbnail&q=80"