import React from 'react';

const adherenceTypes = ['ЛОШО', 'СРЕДНО', 'ДОБРО'];

function Tai10Score({score, adherenceType}){
    const adherence = adherenceTypes[adherenceType];

    return (
        <div className="col-12 px-3">
            <div className="px-lg-5 px-0 mx-lg-5 mx-0">
                <div className="row pt-3">
                    <h4 className="ps-2 pb-2 mb-3 fw-normal fst-italic text-secondary border-bottom border-2 border-gray print-border"><span className="fw-bold text-secondary">Резултат:</span> Ниво на придържане към инхалаторна терапия (TAI 10).</h4>
                </div>
                <div className="row pt-3 justify-content-between print-results-style">
                    <div className="col-sm-3 col-12 pb-sm-2 pb-0 pt-sm-3 pt-2 mb-sm-0 mb-2 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
                        {score.sporadic<25 ? <h5 className="fw-bold" >Непостоянство в придържането</h5> : ""}
                        <h6 className="fw-light">Общ сбор от въпроси 1-5</h6>
                        <h3 className="fw-bold text-secondary"><span>{score.sporadic}</span></h3>
                    </div>
                    <div className="col-sm-3 col-12 pb-sm-2 pb-0 pt-sm-3 pt-2 mb-sm-0 mb-2 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
                        {score.deliberate<25 ? <h5 className="fw-bold" >Съзнателно непридържане</h5> : ""}
                        <h6 className="fw-light">Общ сбор от въпроси 6-10</h6>
                        <h3 className="fw-bold text-secondary"><span>{score.deliberate}</span></h3>
                    </div>
                    <div className="col-sm-5 col-12 pb-2 pt-3 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
                        <div className="">
                            <h5 className="fw-bold">Вашето ниво на придържане е: </h5> 
                            <h3 className="fst-italic fw-bold text-secondary" >{adherence}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tai10Score;

// <div className="col-12 px-3">
//     <div className="px-lg-5 px-0 mx-lg-5 mx-0">
//         <div className="row pt-3">
//             <h4 className="ps-2 pb-2 mb-3 fw-normal fst-italic text-blue border-bottom border-2 border-gray"><span className="fw-bold text-secondary">Резултат:</span> Ниво на придържане към инхалаторна терапия (TAI 10).</h4>
//         </div>
//         <div className="row pt-3 justify-content-between print-results-style">
//             <div className="col-sm-3 col-12 pb-sm-2 pb-0 pt-sm-3 pt-2 mb-sm-0 mb-2 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
//                 <h5 className="fw-bold">Sporadic </h5>
//                 <h6>Total score for items 1 - 5</h6>
//                 <h3 className="fw-bold text-secondary"><span>{score.sporadic}</span></h3>
//             </div>
//             <div className="col-sm-3 col-12 pb-sm-2 pb-0 pt-sm-3 pt-2 mb-sm-0 mb-2 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
//                 <h5 className="fw-bold">Deliberate </h5>
//                 <h6>Total score for items 6 - 10</h6>
//                 <h3 className="fw-bold text-secondary"><span>{score.deliberate}</span></h3>
//             </div>
//             <div className="col-sm-5 col-12 pb-2 pt-3 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
//                 <div className="">
//                     <h5 className="fw-bold">Вашето ниво на придържане е: </h5> 
//                     <h3 className="fst-italic fw-bold text-secondary" >{adherence}</h3>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>