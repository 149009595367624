import React from "react";

import PageContainer from './../PageContainer';
import useRemoteServer from '../../hooks/useRemoteServer';

function Legal() {
    const module = "legal";

    const remoteServerInterface = useRemoteServer();
    const [legal, setLegal] = React.useState({});

    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);

        setLegal(list)
    },[])

    return (
        <PageContainer>
            <h3 className="ps-2 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{legal.Title ? legal.Title : null}</h3>
            <article className="row pb-0 pt-1 pb-xxl-5" dangerouslySetInnerHTML={{__html: legal.Description}}></article>
        </PageContainer>
    );
}

export default Legal;