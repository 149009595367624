import React from 'react';

const adherenceTypes = ['ЛОШО', 'СРЕДНО', 'ДОБРО'];

function Tai12Score({score, adherenceType}){
    const adherence = adherenceTypes[adherenceType];

    return (

        <div className="col-12 px-3">
            <div className="px-lg-5 px-0 mx-lg-5 mx-0">
                <div className="row pt-3">
                    <h4 className="ps-2 pb-2 mb-3 fw-normal fst-italic text-secondary border-bottom border-2 border-gray print-border"><span className="fw-bold text-secondary">Резултати:</span> Оценка за нивото и типа  придържане/непридържане</h4>
                </div>
                <div className="row pt-3 justify-content-between print-results-style">
                    <div className="col-24-percent col-print-3 pb-sm-2 pb-0 pt-sm-3 pt-2 mb-2 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
                        <h5 className="fw-bold">Непостоянство в придържането</h5>
                        <h6 className="fw-light">Общ сбор от въпроси 1-5</h6>
                        <h3 className="fw-bold text-secondary"><span>{score.sporadic}</span></h3>
                    </div>
                    <div className="col-24-percent col-print-3 pb-sm-2 pb-0 pt-sm-3 pt-2 mb-2 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
                        <h5 className="fw-bold">Съзнателно непридържане</h5>
                        <h6 className="fw-light">Общ сбор от въпроси 6-10</h6>
                        <h3 className="fw-bold text-secondary"><span>{score.deliberate}</span></h3>
                    </div>
                    <div className="col-24-percent col-print-3 pb-sm-2 pb-0 pt-sm-3 pt-2 mb-2 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
                        <h5 className="fw-bold">Общ сбор от въпроси 1-10</h5>
                        <h6 className="fw-light"></h6>
                        <h3 className="fw-bold text-secondary"><span>{(score.sporadic && score.deliberate) ? (score.sporadic + score.deliberate) : ""}</span></h3>
                    </div>
                    <div className="col-24-percent col-print-3 pb-sm-2 pb-0 pt-sm-3 pt-2 mb-2 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
                        <h5 className="fw-bold">Несъзнателно непридържане</h5>
                        <h6 className="fw-light">Общ сбор от въпроси 11-12</h6>
                        <h3 className="fw-bold text-secondary"><span>{score.unconscious}</span></h3>
                    </div>
                    <div className="print-result-box col-12 mt-sm-4 mt-2 pb-2 pt-3 d-flex flex-column border border-1 border-blue rounded-3 text-center justify-content-center text-blue">
                        <div className="d-flex justify-content-center align-items-center">
                            <h5 className="fw-bold">Вашето ниво на придържане е: </h5> 
                            <h3 className="fst-italic fw-bold text-secondary ps-2" >{adherence}</h3>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center flex-column flex-sm-row">
                            <h5 className="col-md-6 col-12 text-center text-md-end fw-bold pe-2 print-adherence-heading">{((score.sporadic < 25) || score.deliberate < 25)||(score.unconscious < 4) ? "Вашият тип на непридържане е: " : ""}</h5> 
                            <div className="col-md-6 col-12 text-center text-md-start print-adherence-answers">
                                {score.sporadic<25 ? <h5 className="fst-italic fw-bold text-secondary pe-2" >Непостоянство в придържането</h5> : ""}
                                {score.deliberate<25 ? <h5 className="fst-italic fw-bold text-secondary pe-2" >Съзнателно непридържане</h5> : ""}
                                {score.unconscious<4 ? <h5 className="fst-italic fw-bold text-secondary pe-2" >Несъзнателно непридържане</h5> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tai12Score;
