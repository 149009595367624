import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";  

import {
    ListItem,
    ListItemText,
    MenuItem,
    Popover,
} from "@material-ui/core";
import {ExpandLess, ExpandMore } from "@material-ui/icons";



function MenuNavItem({ item }) {
   
    const [anchorEl, setAnchorEl] = React.useState(null);
   

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const link = item.Alias_slug === 'home' ? '' : item.Alias_slug;
       
    if(item.children.length > 0){
        return (
            <div key={item.Title} className="d-flex align-items-center">
                <ListItem  
                    button
                    aria-controls={"dropdown-menu-"+item.Alias_slug}
                    aria-haspopup="true" 
                    onClick={handleClick} 
                    className="text-secondary text-uppercase w-auto"
                >
                    <ListItemText>
                        {item.Title}
                        {anchorEl ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}
                    </ListItemText>
                </ListItem>
                
                <Popover
                    id={"dropdown-menu-"+item.Alias_slug}
                    anchorEl={anchorEl} 
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{square: true}} 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    
                >
                    {item.children.map((child) => 
                        <MenuItem key={child.Title} onClick={handleClose}>
                            <Link to={`/${link}/${child.Alias_slug}`} className="w-100 align-items-center text-secondary text-decoration-none">
                                {child.Title}
                            </Link> 
                        </MenuItem>
                    )}
                </Popover>
            </div>
        )
    } else {
        return (
            <Link to={`/${link}`} key={item.Title} className="d-flex align-items-center text-uppercase text-secondary text-decoration-none">
                <ListItem button>
                    <ListItemText className="text-center" primary={item.Title} />
                </ListItem>
            </Link>
        )
    }
}

export default MenuNavItem;
