import React from "react";
import {Helmet} from "react-helmet";
 
function Metatags({title, description}){
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={description} />
            <meta name="description" content={description} />
        </Helmet>
    );
}

export default Metatags;


// import React from "react";
// import { Head, withSiteData } from "react-static";

// const Metatags = withSiteData(
//   ({ title, siteName, pageDescription }) => (
//     <Head>
//       <title>{`${title} | ${siteName}`}</title>
//       <meta name="description" content={pageDescription || description} />
//       <meta
//         property="og:description"
//         content={pageDescription || description}
//       />
//       <meta property="og:type" content="article" />
//       <meta property="og:title" content={title} />
//       <meta property="og:site_name" content={siteName} />
//     </Head>
//   )
// );

// export default Metatags;
