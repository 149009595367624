import { Redirect, Link} from "react-router-dom";  

import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText
    } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import * as React from "react";
import { useState } from "react";

import LoginLogoutRegister from '../Authentication/Logout'
  
const SideDrawer = ({navLinks, loggedIn}) => {

        const [state, setState] = useState({ right: false });
  
        const toggleDrawer = (anchor, open) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
            setState({ [anchor]: open });
        };
  
        const handleLogout = () => {
            window.location = `${process.env.PUBLIC_URL}/`;
        }

        const sideDrawerList = anchor => (
            <div
                className="bg-secondary w-100 h-100"
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
                >
                <List component="nav" className="side-nav">
                    {navLinks.map(({ Title, Alias_slug }) => (
                        <Link to={Alias_slug === 'home' ? '/' : Alias_slug} key={Title} className="text-white text-uppercase text-decoration-none">
                            <ListItem button>
                                <ListItemText primary={Title} />
                            </ListItem>
                        </Link>
                    ))}
                    <LoginLogoutRegister loggedIn={loggedIn} onLogout={handleLogout} className="text-white"/>
                </List>
            </div>
        );
  
        return (
            <React.Fragment>
                <IconButton 
                    edge="start"
                    aria-label="menu"
                    onClick={toggleDrawer("right", true)}
                >
                    <Menu fontSize="large" className="text-secondary" />
                </IconButton>
        
                <Drawer
                    anchor="right"
                    open={state.right}
                    // onOpen={toggleDrawer("right", true)}
                    onClose={toggleDrawer("right", false)}
                >
                    {sideDrawerList("right")}
                </Drawer>
            </React.Fragment>
    );
  };
  
  export default SideDrawer;
  