import React from 'react';
import { Redirect, Link} from "react-router-dom";  
import { ListItem, ListItemText } from "@material-ui/core";
import { PersonOutline, Person } from "@material-ui/icons";

function LoginLogoutRegister({loggedIn, onLogout}){

    const handleClickLogout = React.useCallback(() => {
        window.localStorage.clear();
        onLogout();
    }, [onLogout])

    if(!loggedIn){
        return (
            <React.Fragment>
                <Link to='/login' key='Login' className="d-flex align-items-center text-uppercase text-secondary text-decoration-none">
                    <ListItem button>
                        <ListItemText primary='ВХОД' />
                    </ListItem>
                </Link>
                <Link to='/registration' key='Register' className="d-flex align-items-center text-uppercase text-secondary text-decoration-none">
                    <ListItem button>
                        <ListItemText primary='РЕГИСТРАЦИЯ' />
                    </ListItem>
                </Link>
            </React.Fragment>);
    } else {
        const loggedName = localStorage.getItem('taitest.name').split(" ");;
        const headerName= loggedName[loggedName.length-1];

        return (
            <React.Fragment>
                <Link to='' key='Logout' className="d-flex align-items-center text-uppercase text-secondary text-decoration-none">
                    <ListItem button onClick={handleClickLogout}>
                        <ListItemText primary='ИЗХОД' />
                    </ListItem>
                </Link>
                <ListItem className="side-nav-name text-dark text-uppercase w-auto">
                    <Person/>
                    <ListItemText primary={headerName} />
                </ListItem>
            </React.Fragment>
        );
    }
}

export default LoginLogoutRegister; 