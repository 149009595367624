
import React from "react";

import useRemoteServer from '../../hooks/useRemoteServer';

function Cookie() {
    const module = "cookies";

    const remoteServerInterface = useRemoteServer();
    const [cokie, setCokie] = React.useState({});

    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);

        setCokie(list)
    },[])

    return (
        <div>
            <h4 className="ps-2 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{cokie.Title ? cokie.Title : null}</h4>
            <article className="row pb-0 pt-1 pb-xxl-5" dangerouslySetInnerHTML={{__html: cokie.Description}}></article>
        </div>
    );
}

export default Cookie;