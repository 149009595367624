import React from 'react';

import PageContainer from './PageContainer';
import Tai10Questions from './Tai10Questions';
import Tai10Score from './Tai10Score';

import useRemoteServer from '../hooks/useRemoteServer';

function Tai10 (){
    const [savedAnswers, setSavedAnswers] = React.useState(new Map(JSON.parse(window.localStorage.getItem('taitest.tai10.answers'))));

    const handleSubmit = ev => {
        ev.preventDefault();
    }
     
    const remoteServerInterface = useRemoteServer();
    const [questionsData, setQuestionsData] = React.useState([]);

    React.useEffect(async () => {
        const list = await remoteServerInterface.getTai10Questinos();
        setQuestionsData(list.entries)
    },[])


    const [score, setScore] = React.useState({});
    const [adherence, setAdherence] = React.useState();

    
    const handleTai10Ready = React.useCallback(
        (value, answers) => {
            const answersStringified = JSON.stringify(Array.from(answers.entries()));
            window.localStorage.setItem('taitest.tai10.answers', answersStringified);
            
            const sumScores = Number((value.sporadic + value.deliberate));
            let adherenceInx = 0;
            if (sumScores <= 45) {
                adherenceInx = 0;
            } else if((sumScores > 45)&&(sumScores < 50)){
                adherenceInx = 1;
            } else if(sumScores == 50){
                adherenceInx = 2;
            }
        
            setSavedAnswers(answers);
            setScore(value);
            setAdherence(adherenceInx)
        
        },[setSavedAnswers, setScore, setAdherence]);
     

    const handleNewTest = React.useCallback(() => {
        window.localStorage.setItem('taitest.tai10.answers', JSON.stringify([]));
        setSavedAnswers(new Map());
        setScore({});
        setAdherence("");
    }, [setSavedAnswers, setAdherence, setAdherence]);
    
    const handleQuestionSelected = (questionNumber, questionScore) => {
        savedAnswers.set(questionNumber, questionScore);
        setSavedAnswers(new Map(savedAnswers));
      }
    
    return (
        <PageContainer>
            <article className="row px-sm-0 px-1">
                <form onSubmit={handleSubmit} className="tai-test">
                    <Tai10Questions questions={questionsData} onTestReady={handleTai10Ready} answers={savedAnswers} onQuestionSelected={handleQuestionSelected}/>
                    <Tai10Score score={score} adherenceType={adherence} />
                    <div className="col-12 pt-5 pb-3 pe-3 do-not-print">
                        <div className="px-lg-5 px-0 mx-lg-5 mx-0 d-flex justify-content-sm-end justify-content-center">
                            <div className="row">
                                <div className="d-flex justify-content-end pe-5">
                                    <button onClick={handleNewTest} type="button" className="btn btn-blue">Изчисти</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-end pe-0">
                                    <button onClick={window.print} type="button" className="btn btn-blue">Print / Save to PDF</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </article>
        </PageContainer>
    )
}

export default Tai10;