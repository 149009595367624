
import React from "react";
import { useParams } from "react-router-dom";  
import {COCKPIT_HOST, COCKPIT_API_IMAGE, COCKPIT_GET_TOKEN} from "../../config";

import useRemoteServer from '../../hooks/useRemoteServer';

import BlogListing from "./BlogListing";
import BlogDetailed from "./BlogDetailed";
import SidebarMenu from "./SidebarMenu";
import BlogBreadcbumbs from "./BlogBreadcrumbs";

function Blog({coverImage, pageTitle, alias}) {

    const loggedIn = localStorage.getItem('taitest.loggedin');

    const { article } = useParams();

    const remoteServerInterface = useRemoteServer();
    const [navData, setNavData] = React.useState({entries:[]});

    React.useEffect(async () =>{
        const navList = await remoteServerInterface.getNavigationBlog();
        setNavData(navList);
    },[])

    const [blogData, setBlogData] = React.useState({entries:[]});
    // const [filterByPatientData, setFilterByPatientData] = React.useState(loggedIn ? "": 'PATIENT')
    const [startOrder, setStartOrder] = React.useState(0);
    const [displayButton, setDisplayButton] = React.useState(false);

    // if(!loggedIn){
    //     const filter = 'PATIENT'
    //     setFilterByPatientData(filter)
    // } 

    React.useEffect(async () =>{
        if(article){
            const blogList = await remoteServerInterface.getBlogDataHome({"Alias_slug": article});
            setBlogData(blogList);

        }else{ 
            const blogList = await remoteServerInterface.getBlogData(alias, startOrder, loggedIn ? "": 'PATIENT');
            setBlogData(blogList);
            setStartOrder(blogList.entries[blogList.entries.length - 1]._o + 1);
            if (blogList.entries.length > 3) {
                setDisplayButton(true);
            }
        }
    },[])

    const handleClick = async (event) => {
        const blogList = await remoteServerInterface.getBlogData(alias, startOrder);
        const newBlogData = Object.assign({}, blogData, {entries: blogData.entries.concat(blogList.entries)});
        setBlogData(newBlogData);
        if(blogList.entries.length === 0){
            setDisplayButton(false);
        } else {
            setStartOrder(blogList.entries[blogList.entries.length - 1]._o + 1)
        }
    };

    return (
        <React.Fragment>
            <div className="w-100">
                <article className="pb-4">
                    <img className="blog-img w-100" src={coverImage ? COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+coverImage.path+"&w=1920&h=445&o=1&m=thumbnail&q=80" : ""} />
                    <div className="position-relative container">
                        <div className="w-100 cover-page-text position-absolute">
                            <div className="offset-8 col-4 background-light">
                                <span className="pe-4 d-flex justify-content-end">
                                    <h1 className="px-5 header text-uppercase fw-bold text-gray px-4 pt-4 pb-3">{pageTitle ? pageTitle : ""}</h1>
                                </span>
                            </div>
                        </div>
                    </div>   
                </article>
            </div>
            <div className="container pb-5">
                <article className="pb-5">
                    <BlogBreadcbumbs navData={navData} blogData={blogData} alias={alias} article={article}/>
                    <div className="row">
                        <SidebarMenu menuList={navData}/>
                        <div className="col-8">
                            <hr className="pt-1 mb-2 bg-secondary border-secondary border-top border-bottom border-1"/>
                            <div className="border-secondary border-top border-2"></div>
                                {article ? (<BlogDetailed blogItemData={blogData} alaisArticle={article}/>) : (<BlogListing blogListData={blogData} button={displayButton} onLoadMore={handleClick}/>)}
                        </div>
                    </div>
                </article>
            </div>
        </React.Fragment>
    );
}

export default Blog;
