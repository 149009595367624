
import React from "react";

import RedirectAnonymous from '../Authentication/RedirectAnonimus';
import ModalDetailed from "./ModalDetailed"

function BlogDetailed({blogItemData, alaisArticle}) {

    const blogArticleData = blogItemData.entries[0] ? blogItemData.entries[0] : "";
    const articleData = Object.assign({Audience: ''}, blogItemData.entries.find(item => item.Alias_slug === alaisArticle ));

    return (
        <React.Fragment>
            {articleData.Audience === "MEDIC" && (<RedirectAnonymous/>)}
            <div className="row pt-4">
                <div>
                    <h2 className="text-gray fw-bold">{articleData.Title}</h2>
                    <h4 className="pt-1 pb-2 text-secondary">Date: {articleData.Date}</h4>
                    {articleData.Video && <ModalDetailed videoData={articleData}/>}
                    {articleData.Description_preview && <p className="pt-4 fw-bold">{articleData.Description_preview}</p>}
                    {articleData.Subtitle && <h4 className="py-2 text-secondary">{articleData.Subtitle}</h4>}
                    {articleData.Desctiption && <div><p className="fw-bold" dangerouslySetInnerHTML={{__html: articleData.Desctiption}}></p></div>}
                </div>
            </div>
        </React.Fragment>
    );
}

export default BlogDetailed;
