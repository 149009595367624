import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import FilledInput from '@material-ui/core/FilledInput';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
}  from '@material-ui/pickers';


const theme = createMuiTheme({
    typography: {
        fontFamily: 'Montserrat',
        // fontWeightLight: 300,
        fontWeightRegular: 500,
        // fontWeightMedium: 500,
        // fontWeightBold: 700,
        fontSize: 12.5,
        h1: {
            fontSize: "2.1875rem"
        },
        h2: {
            fontSize: "1.75rem"
        },
        h3: {
            fontSize: "1.5rem"
        },
        h4: {
            fontSize: "1.3125rem"
        },
        h5: {
            fontSize: "1rem"
        },
        h6: {
            fontSize: "0.875rem"
        }
    }
}) 

function Tai12AdditionForm ({onChange, data, campaign}){
    
    const handleNameChange = React.useCallback((ev) => {
        onChange(Object.assign({}, data, { Medic_name: ev.target.value }));
    }, [data, onChange]);
    const handleDateChange = React.useCallback((date) => {
        onChange(Object.assign({}, data, { Date: date }));
    },[data, onChange]);
    const handleSexChange = React.useCallback((ev) => {
        onChange(Object.assign({}, data, { Sex: ev.target.value }));
    }, [data, onChange]);
    const handleAgeChange = React.useCallback((ev) => {
        onChange(Object.assign({}, data, { Age: ev.target.value}));
    }, [data, onChange]);
    const handleEducationChange = React.useCallback((ev) => {
        onChange(Object.assign({}, data, { Education: ev.target.value }));
    }, [data, onChange]);
    const handleSmokeChange = React.useCallback((ev) => {
        onChange(Object.assign({}, data, { Smoker: ev.target.value }));
    }, [data, onChange]);
    const handleDiagnosisChange = React.useCallback((ev) => {
        onChange(Object.assign({}, data, { Diagnosis: ev.target.value }));
    }, [data, onChange]);
    const handleDiseaseChange = React.useCallback((ev) => {
        onChange(Object.assign({}, data, { Disease_period: ev.target.value }));
    }, [data, onChange]);
    const handleInhalerChange = React.useCallback((ev) => {
        onChange(Object.assign({}, data, { Inhaler: ev.target.value }));
    }, [data, onChange]);

    return (
        <ThemeProvider theme={theme}>
            <div className="row pt-3 px-lg-5 px-0 mx-lg-5 mx-0">
                {!campaign && (
                    <div className="d-flex flex-flow-wrap justify-content-evenly py-3 print-test-info-style flex-sm-row flex-column text-center">
                        <div className="d-grid print-input-container">
                            <TextField 
                                id="standard-basic" 
                                className="mt-3" 
                                label="Име на пациент: " 
                                // value={data.Medic_name}
                                // onChange={handleNameChange}
                            />
                        </div>
                        <div className="d-grid print-input-container">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} className="col-12 w-100">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Дата: "
                                    // value={selectedDate}
                                    value={data.Date}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                    "aria-label": "change date"
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>)}
                {campaign && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <div className="d-grid print-input-container">
                                {/*<FormControl variant="filled">
                                    <InputLabel htmlFor="component-filled">Лекар: </InputLabel>
                                    <FilledInput id="component-filled" value={data.Medic_name} onChange={handleNameChange} />
                                </FormControl>
                                */}
                                <TextField
                                    className="sans-serif" 
                                    id="standard-basic" 
                                    className="" 
                                    label="Лекар: " 
                                    value={data.Medic_name}
                                    onChange={handleNameChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <div className="print-input-container">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} className="m-0">
                                    <KeyboardDatePicker
                                        className="sans-serif" 
                                        fullWidth
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline"
                                        label="Дата: "
                                        // value={selectedDate}
                                        value={data.Date}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                        "aria-label": "change date"
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <div className="">
                                <div className="">
                                    {/*<FormControl variant="outlined">
                                        <InputLabel htmlFor="component-outlined">Възраст: </InputLabel>
                                        <OutlinedInput id="component-outlined" value={data.Age} onChange={handleAgeChange} />
                                    </FormControl>
                                    */}
                                    
                                    <TextField 
                                        fullWidth
                                        className="sans-serif" 
                                        id="standard-basic" 
                                        variant="standard" 
                                        label="Възраст на пациента: " 
                                        value={data.Age}
                                        onChange={handleAgeChange}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <div className="">
                                <div className="d-grid print-input-container">
                                    <TextField 
                                        fullWidth
                                        className="" 
                                        id="standard-basic" 
                                        variant="standard" 
                                        label="Давност на заболяването (год.): "
                                        value={data.Disease_period} 
                                        onChange={handleDiseaseChange}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={3}>
                            <div className="">
                                <RadioGroup row aria-label="Пол" name="gender" value={data.Sex} onChange={handleSexChange}>
                                    <FormLabel className="d-flex align-items-center pe-3">Пол: </FormLabel>
                                    <FormControlLabel value="Мъжки" control={<Radio />} label="Мъж" />
                                    <FormControlLabel value="Женски" control={<Radio />} label="Жена" />
                                </RadioGroup>
                            </div> 
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={4}>
                            <div className="">
                                <RadioGroup row aria-label="Диагноза" name="diagnosis" value={data.Diagnosis} onChange={handleDiagnosisChange}>
                                    <FormLabel className="d-flex align-items-center pe-3">Диагноза: </FormLabel>
                                    <FormControlLabel value="HOBB" control={<Radio />} label="ХОББ" />
                                    <FormControlLabel value="Астма" control={<Radio />} label="Астма" />
                                </RadioGroup>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={5}>
                            <div className="">
                                <RadioGroup row aria-label="Образование" name="education" value={data.Education} onChange={handleEducationChange}>
                                    <FormLabel className="d-flex align-items-center pe-3">Образование: </FormLabel>
                                    <FormControlLabel value="Основно" control={<Radio />} label="Основно" />
                                    <FormControlLabel value="Средно" control={<Radio />} label="Средно" />
                                    <FormControlLabel value="Висше" control={<Radio />} label="Висше" />
                                </RadioGroup>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                            <div className="">
                                <RadioGroup row aria-label="Тютюнопушене" name="smoke" value={data.Smoker} onChange={handleSmokeChange}>
                                    <FormLabel className="d-flex align-items-center pe-3">Тютюнопушене: </FormLabel>
                                    <FormControlLabel value="Да" control={<Radio />} label="Да" />
                                    <FormControlLabel value="Не" control={<Radio />} label="Не" />
                                </RadioGroup>
                            </div> 
                        </Grid>
                        <Grid item xs={12} xl={8}>
                            <div className="">
                                <RadioGroup row aria-label="Вид инхалатор" name="inhaler" value={data.Inhaler} onChange={handleInhalerChange}>
                                    <FormLabel className="d-flex align-items-center lh-base pe-3">Вид инхалатор за <br/>поддържаща терапия:</FormLabel>
                                    <FormControlLabel value="Спрей" control={<Radio />} label="Спрей под налагане" />
                                    <FormControlLabel value="Прахов" control={<Radio disableRipple/>} label="Прахов инхалатор" />
                                </RadioGroup>
                            </div>
                        </Grid>
                    </Grid>)}
            </div>
        </ThemeProvider>

    )
}

export default Tai12AdditionForm;