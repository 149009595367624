import React from 'react';
import { Redirect } from "react-router-dom";
import {PUBLIC_URL} from "../../config";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import useRemoteServer from '../../hooks/useRemoteServer';
import InputField from '../../input-field'


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: '#AF0061',
        borderRadius: '0',
        marginTop: '24px',
        '&:hover' : {
            background: '#AF0061',
            opacity: '0.8'
        }
    },
}));


function isRequired(val) {
    return val.length > 0 ? '' : 'полето е задължително'
}
function isMoreThan3(val) {
    return (val.length === 0) || (val.length > 2) ? '' : 'не по-малко от 3 букви'
}
function isEmail(val) {
    const at = val.indexOf("@");
    const grDotIdx = val
        .split("")
        .reduce((acc, char, i) => char === '.' ? i : acc, 0);
    return ((val.length === 0) || (at > -1) && (grDotIdx > at)) ? '' : 'въведете валиден email'
}
// function isEmailBusy(val) {
//     return val;
// }
function isMedicID(val) {
    return (val.length === 0) || (!isNaN(val) && (val.length > 9)) ? '' : 'трябва да е 10 цифрeно число'
}
function isPassword(val) {
    const matchPass = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    return (val.length < 8) || matchPass.test(val) ? '' : 'трябва да съдържа поне 1 цифра и 1 латинска буква'
}

function isMoreThan8(val) {
    return (val.length === 0) || (val.length > 7 ) ? '' : 'минимум 8 символа'
}


function Register() {
    const classes = useStyles();

    const remoteServer = useRemoteServer();
    const [loggedIn, setLoggedIn] = React.useState(false);

    const defaultValues = {
        name: '',
        email: '',
        medicID: '',
        pass: ''
    }
    const defaultErrors = {
        name: [],
        email: [],
        medicID: [],
        pass: []
    }

    const [values, setValues] = React.useState(defaultValues);
    const [errors, setErrors] = React.useState(defaultErrors);
    const [serverError, setServerError] = React.useState('')

    const [openModal, setOpenModal] = React.useState(false);

    React.useEffect(()=>{
        setLoggedIn(window.localStorage.getItem('taitest.loggedin'));
    },[loggedIn])

    const handleSubmit = ev => {
        ev.preventDefault();
        if ((errors.name.length + errors.email.length + errors.medicID.length + errors.pass.length) > 0){
            // console.log(errors.values);
            return;
        }

        remoteServer.postRegister(values.name, values.pass, values.email, values.medicID).then(
            (result)=>{
                if(result.error){
                    if (result.error === 'Username is already used!'){
                        setServerError("този адрес е зает вече!"); 
                        console.log(result.error);
                    }
                    // isEmailBusy('Email is already busy')
                } else {
                    remoteServer.postSaveForm(values.name, values.email, values.medicID)
                    .then(remoteServer.postRegisterCollection(values.name, values.email, values.medicID))
                    .then(handleOpenModal());
                }
            }
        )
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        window.location = `${PUBLIC_URL}/`;
    };



    // function validate(validations) {
    //     setErrors(validations
    //         .map(errorsFor => errorsFor(name))
    //         .filter((errorMsg) => errorMsg.length > 0)
    //     );
    // }

    return (
        <React.Fragment>
            {loggedIn && (<Redirect to='/' />)}    
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
            
                    <AccountBoxIcon  className="font-size-80 text-secondary" fontSize="large"/>
                    <h3  className="pt-3">РЕГИСТРАЦИЯ</h3>
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    label = "Име и фамилия *"
                                    name = 'name'
                                    value={values.name}
                                    onChange={val => {
                                        const name = val;
                                        setValues(prev => ({
                                            ...prev,
                                            name
                                        }));
                                    }} 
                                    validations={[isRequired, isMoreThan3]}
                                    errors={errors.name}
                                    setErrors = {setErrors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    label = "Електронен адрес *"
                                    name = 'email'
                                    value={values.email}
                                    onChange={val => {
                                        const email = val;
                                        setValues(prev => ({
                                            ...prev,
                                            email
                                        }));
                                    }} 
                                    validations={[isRequired, isEmail]}
                                    errors={errors.email}
                                    setErrors = {setErrors}
                                    serverError = {serverError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    label = "УИН *"
                                    name = 'medicID'
                                    value={values.medicID}
                                    onChange={val => {
                                        const medicID = val;
                                        setValues(prev => ({
                                            ...prev,
                                            medicID
                                        }));
                                    }} 
                                    validations={[isRequired, isMedicID]}
                                    errors={errors.medicID}
                                    setErrors = {setErrors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    label = "Парола *"
                                    name = 'pass'
                                    value={values.pass}
                                    onChange={val => {
                                        const pass = val;
                                        setValues(prev => ({
                                            ...prev,
                                            pass
                                        }));
                                    }} 
                                    validations={[isRequired, isMoreThan8, isPassword]}
                                    errors={errors.pass}
                                    setErrors = {setErrors}
                                />
                            </Grid>
                        </Grid>
                        <p className="text-gray pt-3">* задължителни полета</p>   
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Регистрирай се
                        </Button>
                        <Grid container justify="flex-start">
                            <Grid item>
                            <Link href="preview/login" variant="body2" className="text-secondary">
                                Вече имате регистрация? Вход
                            </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="d-flex justify-content-center align-items-center"
                    open={openModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={openModal}>
                        <div className="bg-light p-5 text-center">
                            <h2 id="transition-modal-title" className="text-blue pb-4">Благодаря Ви за вашата регистрация!</h2>
                            <p id="transition-modal-description" className="text-secondary">Вашия профил ще бъде активен след одобрение от системата. Очаквайте обратен имейл на посочения от Вас адрес.</p>
                        </div>
                    </Fade>
                </Modal>
            </Container>
        </React.Fragment>
    );
}

export default Register;    


// const [phone, setPhone] = react.useState("");
// const [errors, setErrors] = react.useState({phone: ""});

// const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const {target: {value}} = event;
//     setErrors({phone: ""});
//     setPhone(value);
//     let reg = new RegExp(/^\d*$/).test(value);
//     if (!reg) {
//         setErrors({phone: "Only numbers are permited"})
//     }
// };

// <form onSubmit={handleSubmit} className={classes.form} noValidate>
//     <Grid container spacing={2}>
//         <Grid item xs={12} sm={6}>
//             <TextField
                
                
//                 name="name"
//                 variant="standard"
//                 fullWidth
//                 value={name}
//                 onInput={ e=>setName(e.target.value)}
//                 id="name"
//                 label="First and Last Name"
//                 autoFocus
//                 onBlur={(e)=>{validate([isRequired(e.target.value)])}} 
//                 error={(e)=>{console.log(e)}}
//                 helperText = {errors.length > 0 ? errors.join(', ') : null}                            
//                 />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//             <TextField
//                 required
//                 variant="standard"
//                 fullWidth
//                 value={email}
//                 onInput={ e=>setEmail(e.target.value)}
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 error={error}
//                 helperText={error === "User password required" ? "Email password required" : error}
//             />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//             <TextField 
//                 required
//                 autoComplete="id"
//                 name="medicalID"
//                 variant="standard"
//                 fullWidth
//                 value={medicID}
//                 onInput={ e=>setMedicID(e.target.value)}
//                 id="medicalID"
//                 label="Medical ID"
//                 // error={!medicID}
//                 // helperText={medicID === "" ? 'Need medic ID': ""}
//             />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//             <TextField
//                 required
//                 variant="standard"
//                 fullWidth
//                 value={pass}
//                 onInput={ e=>setPass(e.target.value)}
//                 inputProps={{ pattern: "[a-z]" }}
//                 name="password"
//                 label="Password"
//                 type="password"
//                 id="password"
//                 autoComplete="current-password"
//                 error={error}
//                 helperText={error === "User password required"  ? "Email password required" : error }
//             />
//         </Grid>
//     </Grid>
//     <p className="text-gray pt-3">* required fields</p>   
//     <Button
//         type="submit"
//         fullWidth
//         variant="contained"
//         color="primary"
//         className={classes.submit}
//     >
//         Регистрирай се
//     </Button>
//     <Grid container justify="flex-start">
//         <Grid item>
//         <Link href="preview/login" variant="body2" className="text-secondary">
//             Already have an account? Login
//         </Link>
//         </Grid>
//     </Grid>
// </form>


// <div className={`form-field ${focused ? 'is-focused' : ''} ${name.length > 0 ? 'has-value' : '' }`}>
//     <div className='control'>
//         <label onClick={() => ref.current.focus()} >Name</label>
//         <input
//             ref={ref}
//             type='text'
//             value={name}
//             onChange={(e)=>setName(e.target.value)}
//             onFocus={()=>setFocused(true)}
//             onBlur={()=>{
//                 setFocused(false);
//                 validate([isRequired, isMoreThan3])
//             }}
//         />
//     </div>
//     { errors.length > 0 ? (
//     <div className='has-error'>{errors.join(", ")}</div>
//     ) : null}
// </div>