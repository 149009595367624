import {Link} from "react-router-dom";  
import { PUBLIC_URL, COCKPIT_HOST, COCKPIT_API_IMAGE, COCKPIT_GET_TOKEN } from "../../config";

import {
    AppBar,
    Container,
    Hidden,
    List,
    Toolbar,
    Fab
  } from "@material-ui/core";
import { KeyboardArrowUp, ExpandLess, ExpandMore } from "@material-ui/icons";

import MenuNavItem from "./MenuItem";
import HideOnScroll from "./HideOnScroll";
import SideDrawer from "./SideDrawer";
import BackToTop from "./BackToTop";
import React from "react";
import LoginLogoutRegister from '../Authentication/Logout'


function Header({ logo, menu, loggedIn }) {
    const brandLogoHight = 50; 
    const brandLogoWidth = ''; 
    // const brandLogo = "http://smallairways.clientric.website/api/cockpit/image?token=460ed58b5c2940fea45d4b951379fd&src=http://smallairways.clientric.website"+logo+"&w="+brandLogoWidth+"&h="+brandLogoHight+"&o=1&m=thumbnail&q=80";
    let brandLogo = logo ? COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+logo+"&w="+brandLogoWidth+"&h="+brandLogoHight+"&o=1&m=thumbnail&q=80" : "";
    
    const listNav = menu.map(function(item) {
        return (
            <MenuNavItem item={item} key={item.Alias_slug}/>
        )

    })


    const handleLogout = () => {
        window.location = `${PUBLIC_URL}`;
    }

    return (
        <React.Fragment>
            <HideOnScroll>
                <AppBar className="header-printing position-fixed bg-white">
                    <Toolbar className="header-printing" component="nav">
                        <Container maxWidth="lg" className="d-flex justify-content-between">
                            <div className="d-flex py-2 align-items-center header-logo">
                                <Link to="/" >
                                    <img src={brandLogo} alt="logo"/>
                                </Link>
                            </div>
                            <Hidden smDown>
                                <List component="nav" aria-labelledby="main navigation" className="d-flex justify-content-between do-not-print">
                                    {listNav}
                                    <LoginLogoutRegister className="" loggedIn={loggedIn} onLogout={handleLogout}/>
                                </List>
                            </Hidden>
                            <Hidden mdUp>
                                <SideDrawer navLinks={menu} loggedIn={loggedIn}/>  
                            </Hidden>
                        </Container>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar id="TopAnchor" className="do-not-print" />

            <BackToTop>
                <Fab className="text-secondary do-not-print" size="large" aria-label="scroll back to top">
                    <KeyboardArrowUp />
                </Fab>
            </BackToTop>
        </React.Fragment>
    );
}

export default Header;
