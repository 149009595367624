import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './index.scss'

const App = () => {
    return <Main />;
}

ReactDOM.render(
    <App />,
  document.getElementById('AppBody')
);
