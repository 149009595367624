
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import {COCKPIT_API_IMAGE, COCKPIT_GET_TOKEN, COCKPIT_HOST} from "../../config";

// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/components/pagination/pagination.scss"
import "swiper/components/navigation/navigation.scss"

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination} from 'swiper';
  
// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

function Slider(list) {
    if(list){
        const slideComponents = list.slides.map (function(val, ind) {
            return (
                <SwiperSlide 
                    key={ind} 
                    style={{backgroundImage: `url(${COCKPIT_API_IMAGE}?token=${COCKPIT_GET_TOKEN}&src=${COCKPIT_HOST+val.value.Image.path}&h=720&o=1&m=thumbnail&q=80)`}}>
                    <div className="container slide-text">
                        <div className="offset-4 col-8 background-light">
                            <h1 className="header text-end px-4 pt-4 pb-3">{val.value.title}</h1>
                            <h5 className="description text-end px-4 pb-4">{val.value.description}</h5>
                        </div>
                    </div>
                </SwiperSlide>
            )
        });

        const pagination = {
            "clickable": true,
            "renderBullet": function (index, className) {
                return '<span class=\"' + className + '\"></span>';
            }
        }
    
        return (
            <React.Fragment> 
                <Swiper id="homePageSlider"
                slidesPerView={1}
                loop={true}
                navigation={true}
                pagination={pagination}
                >
                    {slideComponents}
                </Swiper>
            </React.Fragment>
        );    
    }else{
        return <></>;
    }
}

export default Slider;
